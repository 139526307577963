import {
  Circle,
  CreditCard,
  DollarSign,
  Headphones,
  LogOut,
  Users,
  Activity,
  Home,
  Archive,
  User,
  FileMinus,
  Send
} from 'react-feather'

const associatedExtension = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-brand-etsy'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M14 12h-5' />
    <path d='M3 3m0 5a5 5 0 0 1 5 -5h8a5 5 0 0 1 5 5v8a5 5 0 0 1 -5 5h-8a5 5 0 0 1 -5 -5z' />
    <path d='M15 16h-5a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h5' />
  </svg>
)

const payExtensionIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-qrcode'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z' />
    <path d='M7 17l0 .01' />
    <path d='M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z' />
    <path d='M7 7l0 .01' />
    <path d='M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z' />
    <path d='M17 7l0 .01' />
    <path d='M14 14l3 0' />
    <path d='M20 14l0 .01' />
    <path d='M14 14l0 3' />
    <path d='M14 20l3 0' />
    <path d='M17 17l3 0' />
    <path d='M20 17l0 3' />
  </svg>
)

export const mainCommercesItems = [
  {
    header: 'Estadísticas',
    action: 'level-1',
    resource: 'Common'
  },
  {
    id: 'Resumen',
    title: 'Resumen',
    icon: <Activity size={20} />,
    action: 'level-2',
    resource: 'Common',
    navLink: '/commerces/dashboard'
  },

  // TODO: Remover temporalmente
  {
    id: 'Cierre',
    title: 'Historial de cierre',
    icon: <Archive size={20} />,
    action: 'estacionamiento-level-2',
    resource: 'Common',
    navLink: '/parking-lot/closing-history'
  }
]

export default [
  {
    header: 'Depositar',
    action: 'level-0',
    resource: 'Common'
  },

  {
    id: 'notify-mobile-payment',
    title: 'Notificar Pago Movil',
    icon: <CreditCard size={12} />,
    action: 'level-0',
    resource: 'Common',
    navLink: '/commerces/notify/mobile-payment'
  },

  {
    id: 'notify-bank-transfer-payment',
    title: 'Transferencia bancaria',
    icon: <CreditCard size={12} />,
    action: 'level-0',
    resource: 'Common',
    navLink: '/commerces/notify/bank-transfer'
  },

  {
    header: 'Finanzas',
    action: 'level-0',
    resource: 'Common'
  },

  {
    id: 'Retiro',
    title: 'Wallet',
    icon: <CreditCard size={12} />,
    action: 'level-0',
    resource: 'Common',
    navLink: '/commerces/wallet'
  },
  {
    id: 'commerces-history-pay-add',
    title: 'Movimientos',
    action: 'level-2',
    resource: 'Common',
    icon: <FileMinus size={12} />,
    navLink: '/commerces/commerces-transactions'
  },
  {
    id: 'commerces-accounts-add',
    title: 'Cuentas',
    action: 'level-2',
    resource: 'Common',
    icon: <FileMinus size={12} />,
    navLink: '/commerces/accounts'
  },
  {
    id: 'commerces-massive-transfer-add',
    title: 'Transferencia masiva',
    action: 'level-2',
    resource: 'Common',
    icon: <FileMinus size={12} />,
    navLink: '/commerces/massive-transfer'
  },
  {
    id: 'commerces-extensions-pay-add',
    title: 'Cobrar extensión',
    action: 'salud-level-0',
    resource: 'Common',
    icon: payExtensionIcon,
    navLink: '/commerces/pay-extension'
  },

  {
    header: 'Administración',
    action: 'level-1',
    resource: 'Common'
  },
  {
    id: 'workTeam',
    title: 'Operadores',
    action: 'level-1',
    resource: 'Common',
    icon: <User size={20} />,
    navLink: '/commerces/work-team'
  },

  {
    id: 'extensionsCommerces',
    title: 'Extensiones',
    action: 'extension-level-1',
    resource: 'Common',
    icon: <Users size={20} />,
    navLink: '/commerces/commerces-extensions'
  },
  {
    id: 'commerces-extensions-add',
    title: 'Asignar extensión',
    action: 'extension-level-1',
    resource: 'Common',
    icon: associatedExtension,
    navLink: '/commerces/add-extension-accs'
  },

  {
    header: 'Configuración',
    action: 'level-0',
    resource: 'Common'
  },
  {
    id: 'logout',
    title: 'Cerrar sesión',
    icon: <LogOut size={12} />,
    action: 'level-0',
    resource: 'Common',
    navLink: 'commerces/logout'
  }
]
